<template>
  <el-card class="trade-desk-item">
    <header @click="!expanded ? expanded = true : null" class="flex-between">
      <div class="w-full">
        <div class="flex-between">
          <span class="ms-text-l">{{ data.accountId }}</span>
          <span class="ms-text-l">{{ data.tradeNumber }}</span>
          <span class="ms-text-l">{{ data.assetName }}</span>
        </div>

        <div class="flex-between mt-5">
          <span class="ms-text">{{ $date(data.createdAt, 'calendar') }}</span>
          <el-tag
            v-if="data.quoteStatus"
            :type="statusColorClasses[data.quoteStatus]"
            effect="dark"
            size="mini"
            class="right">
            {{ data.quoteStatus | quoteStatus }}
          </el-tag>
        </div>
      </div>
    </header>

    <el-collapse-transition>
      <section v-show="expanded" class="mt-20">
        <ul class="gn-list">
          <li
            v-for="(item, index) in infoList"
            :key="index">
            <label>{{ item.label }}:</label>
            <span>{{ item.text || 'N/A' }}</span>
          </li>
          <li>
            <label>Status:</label>
            <span>
              <el-tag
                v-if="data.quoteStatus"
                :type="statusColorClasses[data.quoteStatus]"
                effect="dark"
                size="mini"
                class="right">
                {{ data.quoteStatus | quoteStatus }}
              </el-tag>
            </span>
          </li>
        </ul>

        <router-link :to="{ name: 'TradeDeskView', params: { id: data.id } }">
          <div class="text-center mt-10">View Detail</div>
        </router-link>
      </section>
    </el-collapse-transition>

    <div class="expand-button" @click.stop="expanded = !expanded">
      <i :class="expanded ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" />
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      expanded: false,

      statusColorClasses: window.quoteStatusColors
    }
  },

  computed: {
    infoList () {
      return [
        {
          label: 'Trade Date',
          text: this.$date(this.data.createdAt, 'calendarFull')
        },
        {
          label: 'Account',
          text: this.data.accountId
        },
        {
          label: 'Quote',
          text: this.quoteNumber
        },
        {
          label: 'Trade',
          text: this.data.trade?.tradeNumber || '/'
        },
        {
          label: 'Type',
          text: this.data.quoteType
        },
        {
          label: 'Asset',
          text: this.data.assetName
        },
        {
          label: 'Amount',
          text: this.$money(this.data.baseAmount)
        },
        {
          label: 'Broker Fee',
          text: this.$money(Math.abs(this.data.feeAmount))
        },
        {
          label: 'Total',
          text: this.$money(this.data.totalAmount)
        },
        {
          label: 'Per Unit',
          text: parseFloat(this.data.pricePerUnit)
        },
        {
          label: 'Unit Count',
          text: parseFloat(this.data.unitCount)
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .trade-desk-item + .trade-desk-item {
    margin-top: 15px;
  }
</style>
