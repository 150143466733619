<template>
  <div>
    <data-list :list="dataList" />

    <div class="info-section">
      <p v-if="accepted">This trade is completed</p>
      <template v-else>
        <p v-if="countDown != 0">This quote expires in {{ countDown }} seconds...</p>
        <p v-else class="error-text">This quote has expired.</p>
        <p v-if="errorSell" class="error-text">Cannot Complete - Quote amount, exceeds available balance.</p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    quote: {
      type: Object,
      required: true
    },

    accepted: {
      type: Boolean,
      required: true
    },

    countDown: {
      type: Number,
      default: null
    },

    errorSell: {
      type: Number,
      default: null
    }
  },

  computed: {
    dataList () {
      return [
        {
          subtitle: 'Quote Details'
        },
        {
          label: 'Date',
          text: this.$date(this.quote.createdAt)
        },
        {
          label: 'Quote',
          text: this.quote.quoteNumber
        },
        {
          label: 'Trade',
          text: this.trade?.tradeNumber,
          condition: !!this.trade
        },
        {
          label: 'Type',
          text: this.quote.quoteType
        },
        {
          label: 'Asset',
          text: this.quote.assetName
        },
        {
          label: 'Base Amount',
          text: this.$money(this.quote.baseAmount)
        },
        {
          label: 'Broker Fee',
          text: this.$money(this.quote.feeAmount)
        },
        {
          label: 'Total Amount',
          text: this.$money(this.quote.totalAmount)
        },
        {
          label: 'Price Per Unit',
          text: this.quote.pricePerUnit
        },
        {
          label: 'Unit Count',
          text: this.quote.unitCount
        },
        {
          label: 'Trade Fee',
          text: this.quote.tradeFee
        },
        {
          label: 'Completed On',
          text: this.$date(this.quote.quoteExecutedAt),
          condition: this.accepted
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .info-section {
    margin-top: 20px;
    p {
      font-size: 16px;
    }
  }
</style>
