<template>
  <div>
    <error-box
      v-if="error"
      :loading="loading"
      @reload="load()" />

    <div v-else>
      <portal to="actions">
        <div class="flex items-center">
          <el-button
            size="small"
            type="success"
            class="mr-5"
            icon="el-icon-plus"
            circle
            @click="showNewTradeDeskModal = true" />
        </div>
      </portal>

      <ms-filters
        :filters="appliedFilters"
        :filters-map="filtersMap"
        @apply="applyFilters($event)" />

      <el-alert
        title="Notice:"
        type="info">
        Please be advised that the Trade Desk currently only supports trades with the following assets: BTC, ETH, LTC, USDC, USDT
      </el-alert>

      <div
        v-if="loading"
        v-loading="loading"
        class="page-loader" />

      <div v-else-if="!!quotes.length" class="mt-15">
        <trade-desk-item v-for="quote in quotes" :key="quote.id" :data="quote" />
      </div>

      <empty v-else>There are no data found.</empty>
    </div>

    <new-trade-desk v-if="showNewTradeDeskModal" @close="showNewTradeDeskModal = false" />
  </div>
</template>

<script>
import moment from 'moment'

import NewTradeDesk from './NewTradeDesk'
import Empty from '@/components/common/ui/Empty'
import ErrorBox from '@/components/common/ui/ErrorBox'
import TradeDeskItem from '../../components/transactions/TradeDeskItem'

export default {
  components: {
    Empty,
    ErrorBox,
    NewTradeDesk,
    TradeDeskItem
  },

  data () {
    return {
      appliedFilters: {},

      error: null,
      loading: true,

      showNewTradeDeskModal: false,

      quoteTypesOptions: window.quoteTypesOptions,
      quoteStatues: window.quoteStatuesOptions
    }
  },

  computed: {
    user () {
      return this.$store.state.app.user
    },

    registration () {
      return this.user?.registration || null
    },

    accounts () {
      return this.registration?.accounts || []
    },

    quotes () {
      return this.$store.state.quotes.data || []
    },

    systemFeatures () {
      return this.$store.state.app.systemFeatures
    },

    filtersMap () {
      return [
        {
          key: 'date',
          label: 'Trade Date',
          type: 'range_datepicker'
        },
        {
          key: 'account',
          label: 'Account',
          type: 'input'
        },
        {
          key: 'quoteNumber',
          label: 'Quote Number',
          type: 'input'
        },
        {
          key: 'tradeNumber',
          label: 'Trade Number',
          type: 'input'
        },
        {
          key: 'quoteType',
          label: 'Type',
          type: 'select',
          options: this.quoteTypesOptions
        },
        {
          key: 'totalAmount',
          label: 'Total Amount',
          type: 'input'
        },
        {
          key: 'quoteStatus',
          label: 'Status',
          type: 'select',
          options: this.quoteStatues
        }
      ]
    }
  },

  created () {
    if (!this.systemFeatures['Trade Desk']) {
      this.$router.push({ name: 'Dashboard' })
      return
    }

    this.$store.commit('page/setPageInfo', {
      title: 'Trade Desk',
      back: { name: 'Home' }
    })
    this.load()
  },

  methods: {
    applyFilters (filters) {
      this.appliedFilters = filters
      this.load()
    },

    async load () {
      this.error = false
      this.loading = true
      try {
        const params = {
          accountId: this.registration?.mainAccount?.accountNumber
        }

        if (!this.appliedFilters || !Object.values(this.appliedFilters).filter(val => val).length) {
          params.createdAt = ['>=', moment('00:00:00', 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')]
        } else {
          if (this.appliedFilters.date?.from) { params.createdAtAfter = ['>=', this.appliedFilters.date.from + ' 00:00:00', 'createdAt'] }
          if (this.appliedFilters.date?.to) { params.createdAtBefore = ['<=', this.appliedFilters.date.to + ' 23:59:59', 'createdAt'] }
          if (this.appliedFilters.account) {
            params.accountId = this.appliedFilters.account
          }

          if (this.appliedFilters.quoteNumber) {
            params.quoteNumber = this.appliedFilters.quoteNumber
          }

          if (this.appliedFilters.tradeNumber) {
            params.tradeNumber = this.appliedFilters.tradeNumber
          }

          if (this.appliedFilters.quoteType) {
            params.quoteType = this.appliedFilters.quoteType
          }

          if (this.appliedFilters.totalAmount) {
            params.totalAmount = this.appliedFilters.totalAmount
          }

          if (this.appliedFilters.quoteStatus) {
            params.quoteStatus = this.appliedFilters.quoteStatus
          }
        }

        await this.$store.dispatch('quotes/load', { order: ['createdAt:DESC'], params, limit: this.perPage, start: (this.page - 1) * this.perPage })
      } catch (error) {
        console.error(error)
        this.error = true
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
