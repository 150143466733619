<template>
  <ms-drawer
    title="New Trade Desk"
    class="gray"
    @close="close()">
    <div v-show="!addConfirmModal">
      <ms-select
        v-model="form.account"
        label="Account"
        :options="accountOptions"
        :validator="$v.form.account"
        filterable />

      <ms-select
        v-model="form.tradeType"
        label="I want to..."
        :options="tradeTypeOptions"
        :validator="$v.form.tradeType" />

      <ms-select
        v-model="form.assetsCurrency"
        label="Assets Type"
        :options="assetsCurrencyOptions"
        :validator="$v.form.assetsCurrency" />

      <ms-select
        v-model="form.depositType"
        label="Amount is in..."
        :options="depositTypeOptions"
        :validator="$v.form.depositType"/>

      <ms-input
        v-if="form.depositType === 'Funds'"
        v-model="form.fundsAmount"
        label="Amount"
        :validator="$v.form.fundsAmount" />

      <ms-input
        v-else
        v-model="form.unitsAmount"
        label="Amount"
        :validator="$v.form.unitsAmount" />

      <div class="error-text" v-if="invalidBalance"><span>Exceeds available balance. Please lower amount and try again.</span></div>

      <div class="text-right">
        <div>Trade Fee: <b>{{ fee | money }}</b></div>
        <div v-if="form.depositType == 'Funds'">Total Amount: <b>{{ totalFunds | money }}</b></div>
        <div v-if="form.depositType == 'Units'">Total Units: <b>{{ totalUnits | assets_balance(10)  }}</b></div>
      </div>
    </div>

    <trade-desk-quote-details
      v-if="addConfirmModal"
      :quote="quote"
      :accepted="accepted"
      :countDown="countDown"
      :errorSell="errorSell" />

    <template slot="footer">
      <div class="flex-buttons">
        <el-button class="max-w-1-3" type="default" round @click="close()">
          {{ countDown > 0 ? 'Cancel' : 'Close' }}
        </el-button>

        <el-button
          v-if="!addConfirmModal"
          type="success"
          class="gradient"
          @click="submit()"
          round>
          Submit
        </el-button>

        <el-button
          v-if="addConfirmModal && !accepted && countDown > 0"
          type="success"
          class="gradient"
          :loading="accepting"
          @click="accept()"
          round>
          Accept
        </el-button>
      </div>
    </template>
  </ms-drawer>
</template>

<script>
import { assetsAmount } from '@/components/common/validations'
import { required, maxValue, decimal } from 'vuelidate/lib/validators'

import TradeDeskQuoteDetails from '@/components/transactions/TradeDeskQuoteDetails'

export default {
  components: {
    TradeDeskQuoteDetails
  },

  data () {
    return {
      form: {
        account: null,
        tradeType: null,
        depositType: null,
        assetsCurrency: null,
        fundsAmount: null,
        unitsAmount: null
      },

      fee: 0,
      countDown: 10,
      countDownRun: true,

      invalidBalance: false,

      addConfirmModal: false,
      quote: null,

      accepted: false,

      error: false,
      failed: false,
      errorSell: false,
      errorMessage: null,

      depositTypeOptions: window.depositTypeOptions,
      tradeTypeOptions: window.quoteTypesOptions,

      saving: false,
      accepting: false
    }
  },

  computed: {
    user () {
      return this.$store.state.app.user
    },

    registration () {
      return this.user?.registration || null
    },

    accounts () {
      return this.registration?.accounts || []
    },

    totalFunds () {
      return (parseFloat(this.form.fundsAmount) + parseFloat(this.fee)) || 0
    },

    totalUnits () {
      return (parseFloat(this.form.unitsAmount)) || 0
    },

    accountOptions () {
      let accounts = []

      if (this.accounts?.length && (!this.user.regId || this.registration.id === this.user.regId)) {
        this.accounts.forEach(account => {
          if (account.profileSettings?.assets && account.profileSettings?.trade_desk) {
            accounts.push({
              id: account.id,
              regId: this.registration.id,
              status: account.accountStatus,
              text: `${account.accountNumber}-${this.registration.name}`,
              accountNumber: account.accountNumber,
              provider: account.provider,
              profileSettings: account.profileSettings,
              fundsCurrencies: account.fundsCurrencies,
              assetsWallets: account.assetsWallets,
              systemAvailableBalance: account.systemAvailableBalance
            })
          }
        })
      }

      accounts = accounts.filter(account => ['Open'].includes(account.status))

      return accounts
    },

    currencies () {
      return this.$store.state.app.currencies.map(currency => ({
        id: currency.id,
        text: currency.currencyNickname
      }))
    },

    assetsList () {
      return this.$store.state.app.currencies
        .filter(currency => currency.systemCurrencyType === 'Assets')
        .map(currency => currency.currencyNickname)
    },

    assetsCurrencyOptions () {
      return this.$store.state.app.currencies.filter(currency => {
        if ([true, '1'].includes(currency.tradeDesk) && currency.systemCurrencyType === 'Assets' && this.selectedAccount?.assetsWallets) {
          const wallet = this.selectedAccount.assetsWallets.find(wallet => wallet.currencyId === currency.id)
          return !!wallet?.incomingAssetWalletAddress
        }
        return false
      }).map(currency => ({
        id: currency.id,
        text: currency.currencyNickname
      }))
    },

    withdrawalLimit () {
      return this.selectedAccount?.profileSettings ? parseFloat(this.selectedAccount?.systemAvailableBalance) : null
    },

    selectedAccount () {
      return this.accounts.find(item => item.id === this.form.account)
    },

    system () {
      return this.$store.state.app.system
    }
  },

  watch: {
    'form.account' () {
      if (this.form.account) {
        this.getFee()
      }
    },

    'form.fundsAmount' () {
      this.$v.form.fundsAmount.$touch()

      if (this.form.tradeType === 'Buy') {
        this.invalidBalance = parseFloat(this.withdrawalLimit) < (parseFloat(this.form.fundsAmount) + parseFloat(this.fee))
      }
    },

    'form.unitsAmount' () {
      this.$v.form.unitsAmount.$touch()
      if (this.form.tradeType === 'Sell') {
        this.invalidBalance = parseFloat(this.unitLimit) < (parseFloat(this.form.unitsAmount))
      }
    }
  },

  methods: {
    close () {
      this.$emit('close')
    },

    async getFee () {
      const payload = {
        profileId: this.form.account,
        transactionType: 'Assets',
        entryType: 'Fee'
      }
      this.fee = (await this.$store.dispatch('transactions/action', {
        id: 'new',
        action: 'GetFee',
        payload
      })).action.fee
    },

    async countDownTimer () {
      if (this.countDownRun) {
        if (this.countDown > 0) {
          setTimeout(() => {
            this.countDown -= 1
            this.countDownTimer()
          }, 1000)
        } else {
          this.trade = (await this.$store.dispatch('quotes/action', {
            id: this.quote.id,
            action: 'Expire'
          })).action.trade
        }
      }
    },

    async accept () {
      this.countDownRun = false
      this.accepting = true
      const response = await this.$store.dispatch('quotes/action', {
        id: this.quote.id,
        action: 'Accept'
      })
      this.trade = response.action.trade
      this.error = response.action.error
      this.failed = response.action.failed
      this.quote = response.item
      this.accepted = true
      this.accepting = false
    },

    async submit () {
      this.$v.$touch()
      if (this.$v.form.$invalid || this.invalidBalance) {
        this.submitError = true
        return
      }

      this.submitError = false

      const loader = this.$loading()
      const payload = {
        profileId: this.form.account,
        createdAt: 'CURRENT_TIMESTAMP',
        fundsAmount: this.form.fundsAmount,
        unitsAmount: this.form.unitsAmount,
        tradeType: this.form.tradeType,
        depositType: this.form.depositType,
        transactionFee: this.fee,
        completedBy: this.user.firstName + ' ' + this.user.lastName,
        currencyId: this.form.assetsCurrency
      }
      const response = await this.$store.dispatch('quotes/action', {
        id: 'new',
        action: 'Quotes',
        payload
      })
      this.quote = response.item
      loader.close()

      if (this.quote.providerCreatedAt) {
        const providerCreatedAtString = this.quote.providerCreatedAt
        const reggie = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/
        const [, year, month, day, hours, minutes, seconds] = reggie.exec(providerCreatedAtString)
        const providerCreatedAt = new Date(year, month - 1, day, hours, minutes, seconds)
        const quoteExpiresAtString = this.quote.quoteExpiresAt
        const quotereggie = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/
        const [, quoteyear, quotemonth, quoteday, quotehours, quoteminutes, quoteseconds] = quotereggie.exec(quoteExpiresAtString)
        const quoteExpiresAt = new Date(quoteyear, quotemonth - 1, quoteday, quotehours, quoteminutes, quoteseconds)
        const diffTime = Math.abs(quoteExpiresAt - providerCreatedAt)
        this.countDown = diffTime / 1000 - 5
        this.countDownRun = true
        this.countDownTimer()
        this.addConfirmModal = true
      } else if (response.action.error) {
        this.error = true
        this.errorMessage = response.action.errorMessage
      } else if (response.action.errorSell) {
        this.errorSell = true
      } else {
        this.form.account = null
        this.form.tradeType = null
        this.form.depositType = null
        this.form.assetsCurrency = null
        this.form.fundsAmount = null
        this.form.unitsAmount = null
        this.error = false
        this.errorSell = false
      }
    }
  },

  validations () {
    const rules = {
      form: {
        account: { required },
        depositType: { required },
        tradeType: { required },
        assetsCurrency: { required }
      }
    }
    if (this.form.depositType === 'Funds') {
      rules.form.fundsAmount = { required, decimal }
      if (this.form.tradeType === 'Buy') {
        if (this.tradeLimit) {
          rules.form.fundsAmount.limit = maxValue(this.tradeLimit)
        }
      }
    }
    if (this.form.depositType === 'Units') {
      rules.form.unitsAmount = { required, assetsAmount }
      if (this.form.tradeType === 'Sell') {
        if (this.unitLimit) {
          rules.form.unitsAmount.limit = maxValue(this.unitLimit)
        }
      }
    }
    return rules
  }
}
</script>
